import React from 'react';
import { Link } from 'gatsby'

export default [
  {
    id: 1,
    categoryTitle:
            'Livraison',
    sections: [
      {
        title: (
          <h3>
            En combien de temps puis-je me faire livrer les produits recommandés par les
            sexologues&nbsp;?
          </h3>
        ),
        body: (
          <>
            <p>
              Les livraisons de produits recommandés par les sexologues se font sous 48H par le
              transporteur GLS ou en 24H par le DHL.
            </p>
          </>
        ),
      },
      {
        title: (
          <h3>
            À quoi ressemblent les colis&nbsp;?
          </h3>
        ),
        body: (
          <p>
            Les colis sont préparés pour entrer dans une boîte aux lettres et sont totalement
            neutres
          </p>
        ),
      },
      {
        title: (
          <h3>
            Où puis-je me faire livrer&nbsp;?
          </h3>
        ),
        body: (
          <p>
            Les livraisons peuvent avoir lieu à l'adresse de votre choix ou dans un relais colis.
          </p>
        ),
      },
    ],
  },
  {
    id: 2,
    categoryTitle: 'Téléconsultation avec nos sexologues partenaires',
    sections: [
      {
        title: (
          <h3>
            Comment téléconsulter une sexologue sur Mia ?
          </h3>
        ),
        body: (
          <>
            <p>
              Pour téléconsulter un médecin sur Mia.co, vous
              devez :
            </p>
            <ol>
              <li>
                Remplir le questionnaire en ligne accessible <Link to="/consulter/">ici</Link>
              </li>
              <li>
                Planifier votre RDV qui se fera au choix par
                messagerie sécurisée, par téléphone ou par
                visioconférence.
              </li>
              <li>
                Confirmer votre RDV en renseignant vos
                informations personnelles et en pré-réglant la
                téléconsultation sur la plateforme.
              </li>
              <li>
                Une fois la consultation terminée, RDV dans
                votre espace personnel Mia.co pour consulter
                votre dossier médical et l’ordonnance du médecin
                le cas échéant.
              </li>
            </ol>
          </>
        ),
      },
      {
        title: (
          <h3>
            Comment décaler ou annuler une consultation sur Mia&nbsp;?
          </h3>
        ),
        body: (
          <p>
            Vous pouvez décaler ou annuler une consultation depuis
            votre espace personnel. En cas d’annulation plus de 24h
            avant l’heure du RDV, le pré-règlement vous sera
            restitué sur le compte de la carte bancaire utilisée
            lors du paiement.
          </p>
        ),
      },
      {
        title: (
          <h3>
            Est-il possible de téléconsulter sur Mia.co depuis un pays
            étranger&nbsp;?
          </h3>
        ),
        body: (
          <p>
            Oui, vous pouvez consulter en ligne les sexologues de la plateforme Mia.co depuis n’importe
            quel pays au choix par messagerie sécurisée, appel téléphonique ou visioconférence.
          </p>
        ),
      },
      {
        title: (
          <h3>Que se passe-t-il si la sexologue sur Mia ne peut pas m’aider&nbsp;?</h3>
        ),
        body: (
          <>
            <p>
              La téléconsultation ne suffit pas toujours à remplacer une consultation en cabinet. Si
              votre sexologue sur Mia considère que c’est le cas, il vous aidera à vous orienter
              vers un autre lieu de soins et d’autres professionnels de santé.
            </p>
          </>
        ),
      },
      {
        title: (
          <h3>
            Est-ce possible de réserver pour quelqu’un d’autre que
            moi&nbsp;?
          </h3>
        ),
        body: (
          <p>
            Non, il n’est pas possible de réserver pour quelqu’un d’autre que vous. Votre compte Mia
            est strictement personnel. Néanmoins, il vous est possible de consulter avec votre
            partenaire si vous êtes en couple et que vous le souhaitez.
          </p>
        ),
      },
      {
        title: <h3>C&apos;est une urgence, que dois-je faire ?</h3>,
        body: (
          <p>
            Mia n’est pas une plateforme d’urgence. En cas d'urgence, veuillez composer le 15 ou le
            112 (numéro d’urgence européen).
          </p>
        ),
      },
      {
        title: (
          <h3>
            Combien coûte la consultation avec une sexologue sur Mia&nbsp;?
          </h3>
        ),
        body: (
          <>
            <p>
              Les sexologues présentes sur la plateforme Mia.co proposent des consultations de 30
              minutes à 45 euros.
            </p>
          </>
        ),
      },
      {
        title: (
          <h3>
            Tout le monde peut-il bénéficier d’une téléconsultation sur Mia.co&nbsp;?
          </h3>
        ),
        body: (
          <>
            <p>
              Pour bénéficier d’une téléconsultation sur Mia, vous devez être une femme et/ou une
              personne à vulve.
            </p>
          </>
        ),
      },
      {
        title: (
          <h3>Qui sont les sexologues sur Mia.co&nbsp;?</h3>
        ),
        body: (
          <>
            <p>
              Toutes les praticiennes de la plateforme Mia.co sont diplômées en sexologie. D’horizons
              divers (psychologues, sage-femmes, thérapeutes de couples), elles se retrouvent
              autour d’une conception commune de la sexologie et ont toutes été spécialement formées à
              la pratique de la téléconsultation.
            </p>
            <p>
              Elles sont encadrées par un comité scientifique composé de médecins sexologues et de
              professeurs d’université en sexologie et de membres dirigeants d’association de
              sexologues.
            </p>
          </>
        ),
      },
      {
        title: (
          <h3>
            Est-il nécessaire d’effectuer la téléconsultation sous
            forme de visioconférence&nbsp;?
          </h3>
        ),
        body: (
          <p>
            Non, vous pouvez consulter de 3 façons différentes à votre convenance : par messagerie sécurisée, visioconférence ou appel téléphonique.
          </p>
        ),
      },
      {
        title: (
          <h3>
            La consultation est-elle remboursée par la sécurité sociale ou les mutuelles&nbsp;?
          </h3>
        ),
        body: (
          <>
            <p>
              La téléconsultation de sexologie n’est pas remboursée par la sécurité sociale.
              Cependant, certaines mutuelles peuvent prendre en charge une partie ou la totalité de
              votre consultation, selon votre contrat. Nos sexologues seront en mesure de vous
              fournir une facture sur demande à adresser à votre complémentaire de santé.
            </p>
          </>
        ),
      },
    ],
  },
  {
    id: 3,
    categoryTitle: 'Confidentialité et Sécurité',
    sections: [
      {
        title: (
          <h3>Mes données personnelles sont-elles en sécurité&nbsp;?</h3>
        ),
        body: (
          <p>
            Nous prenons votre vie privée et la sécurité de vos données très au sérieux. Nous avons
            conçu la plateforme Mia en nous conformant à toutes les lois en vigueur en matière de
            confidentialité et de sécurité pour protéger vos données. Notre site est
            régulièrement audité afin de garantir sa sécurité.
          </p>
        ),
      },
      {
        title: (
          <h3>
            Qui consulte mes données de santé et comment sont-elles stockées et protégées&nbsp;?
          </h3>
        ),
        body: (
          <>
            <p>
              La confidentialité de vos données de santé est absolument centrale pour Mia et nous
              attachons une attention particulière à la protéger.
            </p>
            <ol>
              <li>
                Accès à votre espace personnel par authentification forte : suite à la saisie de
                votre adresse mail et de votre mot de passe, un SMS d’authentification vous sera
                envoyé.
              </li>
              <li>
                Sécurisation de données médicales : pour garantir la sécurité, la confidentialité et
                l’intégrité de vos données de santé, Mia les confie à un Hébergeur Agréé Données de
                Santé (HADS) auprès de l’Agence Française de la Santé Numérique (ASIP).
              </li>
              <li>
                Vos informations sont accessibles uniquement par les personnes habilitées. L’accès aux
                données de santé des patientes Mia est régulé. Seuls les sexologues auront accès à
                vos données de santé.
              </li>
            </ol>
          </>
        ),
      },
      {
        title: (
          <h3>
            Comment sont sécurisées mes informations bancaires&nbsp;?
          </h3>
        ),
        body: (
          <>
            <p>
              Pour la sécurité de vos informations bancaires Mia.co s’appuie sur la solution de
              paiement MANGOPAY, un des leaders Européen qui est conforme aux normes PSD2, GDPR et
              PCI DSS
            </p>
          </>
        ),
      },
      {
        title: (
          <h3>
            Comment puis-je régler mes consultations sur Mia&nbsp;?
          </h3>
        ),
        body: (
          <p>
            Les consultations peuvent être payées par carte bancaire (Visa, CB, Mastercard) ou
            Paypal.
          </p>
        ),
      },
      {
        title: (
          <h3>
            Mes consultations sont-elles privées&nbsp;?
          </h3>
        ),
        body: (
          <>
            <p>
              Toutes les téléconsultations sur la plateforme Mia.co sont effectuées dans le plus strict respect du secret médical.
            </p>
          </>
        ),
      },
    ],
  },
];
