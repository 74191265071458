import React from 'react';
import CharlesMeta from 'components/common/meta/CharlesMeta';
import cx from 'classnames';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Section from 'components/common/Section';
import { Layout } from 'components/common';
import TextSectionList from 'components/common/TextSectionList';
import categories from './data';
import FAQTextSectionList from '../common/FAQTextSectionList/FAQTextSectionList';

const IndexPage = () => (
  <Layout
    showSextionBeforeFooter={false}
    showLinksInFooter={false}
    showNewsletterBeforeFooter={false}
  >
    <CharlesMeta
      data={{
        title: 'FAQ | Foire Aux Questions | Mia.co',
        description:
        "Comment téléconsulter une sexologue sur Mia.co ? Combien coûte une téléconsultation ? Qui sont les sexologues ? Est-ce remboursé par la sécurité sociale ? FAQ",
      }}
    />
    <div className="text-left">
      <Section className="mt-lg-0 pb-lg-5 main-section faq-landing-section patho-top-section">
        <Container>
          <div className="h1 text-center">
            <h1 className="responsive-faq-title">Foire Aux Questions</h1>
          </div>
        </Container>
      </Section>
      <Section className="pb-0 pb-md-1 mt-0">
        <Container>
          <Row className="faq-map-row">
            <Col xs={12} lg={5} className="faq-about-charles-col">
              <div className="faq-accordion-div">
                <h2 className="section-title faq-category-title">A propos de Mia.co</h2>
                <p className="faq-accordion-sections text-left">
                  Mia.co est une plateforme digitale qui accompagne les femmes pour leurs problèmes sexuels et intimes à distance.
                  Mia.co est une solution de téléconsultation qui met la patiente en relation avec
                  des sexologues diplômées et permet ensuite la mise en place d’un accompagnement
                  personnalisé en fonction de ses besoins (nombre de séances, traitements…)
                </p>
              </div>
              <div className="faq-accordion-div">
                <h2 className="section-title faq-category-title">{categories[1].categoryTitle}</h2>
                <FAQTextSectionList
                  sections={categories[1].sections}
                  openFirst={false}
                  className="d-block d-sm-none faq-accordion-sections"
                />
                <FAQTextSectionList
                  sections={categories[1].sections}
                  openFirst={false}
                  className="d-none d-sm-block faq-accordion-sections"
                />
              </div>
            </Col>
            <Col
              xs={12}
              lg={{ offset: 2, span: 5 }}
            >
              <div className="faq-accordion-div">
                <h2 className="section-title faq-category-title">{categories[0].categoryTitle}</h2>
                <FAQTextSectionList
                  sections={categories[0].sections}
                  openFirst={false}
                  className="d-block d-sm-none faq-accordion-sections"
                />
                <FAQTextSectionList
                  sections={categories[0].sections}
                  openFirst={false}
                  className="d-none d-sm-block faq-accordion-sections"
                />
              </div>
              <div className="faq-accordion-div">
                <h2 className="section-title faq-category-title">{categories[2].categoryTitle}</h2>
                <FAQTextSectionList
                  sections={categories[2].sections}
                  openFirst={false}
                  className="d-block d-sm-none faq-accordion-sections"
                />
                <FAQTextSectionList
                  sections={categories[2].sections}
                  openFirst={false}
                  className="d-none d-sm-block faq-accordion-sections"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </Section>
    </div>
  </Layout>
);

export default IndexPage;
