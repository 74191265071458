import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import BulletNumber from 'components/common/BulletNumber';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

import './styles.sass';

const FAQTextSectionList = (props) => {
  const { sections, openFirst, className } = props;
  return (
    <Accordion
      className={cx('faq-text-accordion text-left', className)}
      allowMultipleExpanded
      allowZeroExpanded
      preExpanded={openFirst ? [0] : []}
    >
      {sections.map((el, index) => (
        <AccordionItem uuid={index} key={el.id} className="faq-accordion-item">
          <AccordionItemHeading>
            <AccordionItemButton className="faq-custom-accordion-button">
              <div className="d-flex flex-row">
                <div className="faq-accordion-button-label text-left">
                  {el.title}
                </div>
              </div>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel className="faq-accordion-body">
            <div className="d-flex flex-row">
              <div className="faq-blue-bar" />
              <div>{el.body}</div>
            </div>
          </AccordionItemPanel>
        </AccordionItem>
      ))}
    </Accordion>
  );
};

FAQTextSectionList.propTypes = {
  openFirst: PropTypes.bool,
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.node.isRequired,
      body: PropTypes.node.isRequired,
    }),
  ).isRequired,
};
FAQTextSectionList.defaultProps = {
  openFirst: true,
};

export default FAQTextSectionList;
